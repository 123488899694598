import { IndustryService } from 'services/industry-service';
import { RecyclingCompanyService } from 'services/recycling-company-service';
import { DimensionClassService } from 'services/dimension-class-service';
import { NetStatusService } from 'services/net-status-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, bindable } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { CustomerService } from 'services/customer-service';
import { ErrorService } from 'services/error-service';
import { ServiceStationService } from 'services/service-station-service';
import { ServicePriorityService } from 'services/service-priority-service';
import { SiteService } from 'services/site-service';
import { ServiceStationStorageService } from 'services/service-station-storage-service';
import { NetTypeService } from 'services/net-type-service';
import { NetShapeService } from 'services/net-shape-service';
import * as moment from 'moment';
import { ServiceService } from 'services/service-service';
import { UserService } from 'services/user-service';
import { ProducerService } from 'services/producer-service';
import { SpecialProductTypeService } from 'services/special-product-type-service';
import { Models } from 'models/core';
import { NetFilter } from 'lib/tables/NetFilter';
import { Industry } from 'models/Industry';

@autoinject
export class NetFilters {
  @bindable private netFilters: any;
  @bindable private selectedFilters: string[];
  @bindable private getQuery;
  @bindable private context;
  @bindable private fields;
  @bindable private pageSize;
  @bindable private getNets;
  @bindable private toggleVisible;
  @bindable private getKey;
  @bindable private isNetList;
  @bindable private customerId;
  @bindable private clearAll;
  @bindable private industryId?: number;
  @bindable private industry?: Industry;

  private netFilterTypes = {
    CHECKBOX: 'checkbox',
    RADIO: 'radio',
    RADIO_SIMPLE: 'radioSimple',
    RANGE: 'range',
    RANGE_DATE: 'rangeDate',
  };

  private netFilterInputInFocus: object = {};
  private netFilterDatepickerInFocus: any;

  constructor(
    private serviceService: ServiceService,
    private customerService: CustomerService,
    private errorService: ErrorService,
    private netStatusService: NetStatusService,
    private recyclingCompanyService: RecyclingCompanyService,
    private eventAggregator: EventAggregator,
    private serviceStationService: ServiceStationService,
    private servicePriorityService: ServicePriorityService,
    private siteService: SiteService,
    private serviceStationStorageService: ServiceStationStorageService,
    private netTypeService: NetTypeService,
    private netShapeService: NetShapeService,
    private dimensionClassService: DimensionClassService,
    private i18n: I18N,
    private userService: UserService,
    private producerService: ProducerService,
    private specialProductTypeService: SpecialProductTypeService,
    private industryService: IndustryService
  ) {}

  private attached() {
    this.getQuery = this.netFilterGetFiltersQuery.bind(this);
    this.toggleVisible = this.netFilterToggleVisible.bind(this);
    this.clearAll = this.clearAllNetFilters.bind(this);
  }

  // Helper-function for netFilters
  private netFilterValuesToArray(netFilter) {
    if (!netFilter?.values || Object.keys(netFilter.values).length == 0) {
      return null;
    }

    const values = Object.entries(netFilter?.values);
    const data = values?.map((it) => {
      const item = {
        key: it[0],
        value: it[1],
        object: null,
      };

      const objectRef = netFilter.options.find((it) => it?.Id == item.key);
      if (objectRef) {
        item.object = objectRef;
      }

      return item;
    });

    return data;
  }

  private i18nName(row) {
    if (!row) {
      return null;
    }

    const lang = this.i18n.getLocale();
    switch (lang) {
      case 'nb-NO':
        return row['Name'];
      case 'en':
        return row['NameEn'] ? row['NameEn'] : row['Name'];
      case 'es':
        return row['NameEs'] ? row['NameEs'] : row['NameEn'];
      default:
        return row['Name'];
    }
  }

  // Sets the selected-label that shows in the dropdown
  // Converts array to compact string etc.
  private async setNetFilterValueLabel(netFilter) {
    if (typeof netFilter != 'object') {
      return;
    }

    // Checkbox
    if (netFilter.type === this.netFilterTypes.CHECKBOX) {
      const values: any = this.netFilterValuesToArray(netFilter)
        ?.filter((it) => it.value == true)
        ?.sort((a, b) => {
          if (a?.object?.SortIndex && b?.object?.SortIndex) {
            return a.object.SortIndex > b.object.SortIndex ? 1 : -1;
          } else {
            return 0;
          }
        });

      const fullString = values?.map((it: any) => this.i18nName(it.object)).join(', ');

      if (!values || values.length == 0) {
        netFilter._valueLabel = this.i18n.tr('servicefilters.noneSelected');
        netFilter._valueIsNone = true;
      } else if (values.length == 1) {
        netFilter._valueLabel = fullString.substring(0, 16);
        netFilter._valueIsNone = false;
      } else if (values.length > 1 && fullString.length > 16) {
        netFilter._valueLabel = `${fullString.substring(0, 16)} ...`;
        netFilter._valueIsNone = false;
      } else {
        netFilter._valueLabel = fullString.substring(0, 16);
        netFilter._valueIsNone = false;
      }
    }

    // Range
    if (netFilter.type === this.netFilterTypes.RANGE) {
      if (netFilter.values.from && netFilter.values.to) {
        netFilter._valueLabel = `${netFilter.values.from} - ${netFilter.values.to}`;
        netFilter._valueIsNone = false;
      } else if (netFilter.values.from) {
        netFilter._valueLabel = `${this.i18n.tr('servicefilters.rangeFrom').toLowerCase()} ${netFilter.values.from}`;
        netFilter._valueIsNone = false;
      } else if (netFilter.values.to) {
        netFilter._valueLabel = `${this.i18n.tr('servicefilters.rangeTo').toLowerCase()} ${netFilter.values.to}`;
        netFilter._valueIsNone = false;
      } else {
        netFilter._valueLabel = this.i18n.tr('servicefilters.noneSelected');
        netFilter._valueIsNone = true;
      }
    }

    // Range Date
    if (netFilter.type === this.netFilterTypes.RANGE_DATE) {
      if (netFilter.values.from && netFilter.values.to) {
        netFilter._valueLabel = `${moment.utc(netFilter.values.from).format('DD.MM.YYYY')} - ${moment
          .utc(netFilter.values.to)
          .format('DD.MM.YYYY')}`;
        netFilter._valueIsNone = false;
      } else if (netFilter.values.from) {
        netFilter._valueLabel = `${this.i18n.tr('servicefilters.rangeFrom').toLowerCase()} ${moment
          .utc(netFilter.values.from)
          .format('DD.MM.YYYY')}`;
        netFilter._valueIsNone = false;
      } else if (netFilter.values.to) {
        netFilter._valueLabel = `${this.i18n.tr('servicefilters.rangeTo').toLowerCase()} ${moment
          .utc(netFilter.values.to)
          .format('DD.MM.YYYY')}`;
        netFilter._valueIsNone = false;
      } else {
        netFilter._valueLabel = this.i18n.tr('servicefilters.noneSelected');
        netFilter._valueIsNone = true;
      }
    }

    // Radio simple
    if (netFilter.type === this.netFilterTypes.RADIO_SIMPLE) {
      const selected = netFilter.options.find(({ value }) => value === netFilter.values);
      netFilter._valueLabel = selected.label;
    }

    // Radio
    if (netFilter.type === this.netFilterTypes.RADIO) {
      setTimeout(() => {
        const selected = netFilter.options.find(({ Id }) => Id === netFilter.values);
        netFilter._valueLabel = this.i18nName(selected) ?? this.i18n.tr('servicefilters.noneSelected');
        netFilter._valueIsNone = this.i18nName(selected) ? false : true;
      });
    }
  }

  // Sets the _selectedOptions on applicable filters
  // This array is just a helper for the frontend-logic and repeaters
  private setNetFilterSelectedOptions(netFilter) {
    if (typeof netFilter !== 'object') {
      return;
    }

    if (netFilter.type === this.netFilterTypes.CHECKBOX) {
      const selected = netFilter.options.filter((option) => {
        return netFilter.values[option.Id];
      });

      netFilter._selectedOptions = selected;
    }
  }

  private async setNetFiltersDisabled() {
    for (const [key, netFilter] of Object.entries(this.netFilters) as any) {
      if (!netFilter) {
        continue;
      } else if (typeof netFilter !== 'object') {
        continue;
      }

      if (netFilter.disabled !== undefined) {
        if (typeof netFilter.disabled === 'function') {
          this.netFilters[key]._disabled = netFilter.disabled(this.netFilters);
        } else {
          this.netFilters[key]._disabled = netFilter.disabled;
        }

        this.eventAggregator.publish('netFilterOptionsRefresh', key);
      }
    }
  }

  private netFilterGetFiltersQuery(fields = null): Promise<object> {
    return new Promise(async (resolve, reject) => {
      if (!this.netFilters) {
        await this.setupNetFilters();
      }

      const query: object = {};

      const valuesToArray = (netFilter) => {
        if (netFilter.type === this.netFilterTypes.CHECKBOX) {
          return (
            Object.entries(netFilter?.values)
              .filter(([key, value]) => !!value)
              .map(([key]) => key) ?? []
          );
        } else {
          return netFilter?.values;
        }
      };

      const filters: Array<[string, any]> = Object.entries(this.netFilters);
      for (let [filterKey, filter] of filters) {
        if (!filter || typeof filter !== 'object') {
          continue;
        }

        // Has custom query
        if (filter.query) {
          // Netfilter uses custom query-key
          if (typeof filter.query === 'string') {
            const values = valuesToArray(filter);
            if (values?.length > 0) {
              query[filter.query] = valuesToArray(filter);
            }
          }

          // Netfilter uses custom query-function
          else if (typeof filter.query === 'function') {
            // Multiple values i returned
            const values = filter.query(filter.values);
            if (Array.isArray(values)) {
              if (values.length > 0) {
                values.forEach(({ key, value }) => {
                  query[key] = value;
                });
              }
            }

            // Single value is returned
            else {
              if (values.value && values.value !== '') {
                query[values.key] = values.value;
              }
            }
          }
        }

        // Use default query-handling
        else {
          // Range
          if (filter.type === this.netFilterTypes.RANGE) {
            query[`${filter.name}From`] = filter.values.from;
            query[`${filter.name}To`] = filter.values.to;
          }

          // Range Date
          if (filter.type === this.netFilterTypes.RANGE_DATE) {
            query[`${filter.name}From`] = filter.values.from
              ? moment.utc(filter.values.from).format('YYYY-MM-DD')
              : null;
            query[`${filter.name}To`] = filter.values.to ? moment.utc(filter.values.to).format('YYYY-MM-DD') : null;
          }

          // Radio simple
          if (filter.type === this.netFilterTypes.RADIO_SIMPLE) {
            if (filter.values !== null) {
              query[filter.name] = filter.values;
            }
          }

          // Radio
          if (filter.type === this.netFilterTypes.RADIO) {
            query[filter.name] = filter.values;
          }
        }
      }

      // Misc
      query['_select'] = fields
        ? fields
            ?.filter((x) => x.selected && !x.function)
            .map((x) => x.field)
            .join(',')
        : this.fields
            ?.filter((x) => x.selected && !x.function)
            .map((x) => x.field)
            .join(',');
      query['_export'] = false;
      query['top'] = this.pageSize;

      // Timeout so this.netFilters can be updated
      setTimeout(() => {
        return resolve(query);
      });
    });
  }

  // Radio-buttons needs a middle-function because of some
  // binding-issues, and because the click-event has to
  // get a true in return
  private netFilterRadioChangeProxy(netFilter, option, event) {
    setTimeout(() => {
      this.netFilterChange(netFilter, option, event);
    });
    return true;
  }

  // Datepickers need a middle-function to better handle changes
  private netFilterDateChangeProxy(netFilter, option, event) {
    if (!event.firedBy) {
      return this.netFilterChange(netFilter, option, event);
    }

    const date = event.firedBy._d;
    if (
      date &&
      netFilter?.values[option] &&
      moment.default(date).format('YYYY-MM-DD') == moment.default(netFilter?.values[option]).format('YYYY-MM-DD')
    ) {
      return;
    }

    // hack because datepicker is slow
    setTimeout(() => {
      this.netFilterChange(netFilter, option, event);
    }, 500);
  }

  private async netFilterChange(netFilter, option, event) {
    // Filter has custom change-function
    if (netFilter.change) {
      netFilter.change(option, event, netFilter.values);
    }

    // Use default change-functions
    else {
      if (netFilter.type === this.netFilterTypes.CHECKBOX) {
        netFilter.values[option.Id] = event.target.checked;
        netFilter._clearable = Object.values(netFilter.values).includes(true);
      }

      if (netFilter.type === this.netFilterTypes.RANGE) {
        netFilter._clearable = netFilter.values.from || netFilter.values.to;
      }

      if (netFilter.type === this.netFilterTypes.RANGE_DATE) {
        netFilter.values[`${netFilter.name}${option}`] = event.detail;
        netFilter._clearable = netFilter.values.from || netFilter.values.to;
      }

      if (netFilter.type === this.netFilterTypes.RADIO_SIMPLE) {
        this.netFilters[netFilter.name].values = option.value;
        netFilter._clearable = netFilter.values !== null;
      }

      if (netFilter.type === this.netFilterTypes.RADIO) {
        this.netFilters[netFilter.name].values = option.Id;
        netFilter._clearable = netFilter.values !== null;
      }
    }

    this.setNetFilterSelectedOptions(netFilter);
    this.setNetFilterValueLabel(netFilter);
    this.setNetFiltersDisabled();
    this.getNets();
    this.netFiltersSaveLocal();
  }

  private async netFilterClearSingle(serviceFilter) {
    await this.netFilterClear(serviceFilter, true);
    await this.getNets();
  }

  private async netFilterClear(netFilter, useDefaults = false, batch = false) {
    if (typeof netFilter == 'string') {
      netFilter = '';
    } else if (netFilter?.clear && !useDefaults) {
      netFilter.clear(netFilter.values);
    } else {
      // Use default clear-functions

      // Checkbox
      if (netFilter?.type === this.netFilterTypes.CHECKBOX) {
        for (const [key, value] of Object.entries(netFilter.values)) {
          netFilter.values[key] = false;
        }
      }

      // Radio
      if (netFilter?.type === this.netFilterTypes.RADIO) {
        netFilter.values = null;
      }

      // Radio simple
      if (netFilter?.type === this.netFilterTypes.RADIO_SIMPLE) {
        netFilter.values = null;
      }

      // Range
      if (netFilter?.type === this.netFilterTypes.RANGE) {
        netFilter.values.from = null;
        netFilter.values.to = null;
      }

      // Range Date
      if (netFilter?.type === this.netFilterTypes.RANGE_DATE) {
        netFilter.values.from = null;
        netFilter.values.to = null;
      }
    }
    if (netFilter) {
      if (typeof netFilter == 'object') {
        netFilter._clearable = false;
      }

      this.setNetFilterSelectedOptions(netFilter);
      this.setNetFilterValueLabel(netFilter);
    }

    if (!batch) {
      this.setNetFiltersDisabled();
      this.netFiltersSaveLocal();
    }

    if (!useDefaults && !batch) {
      this.getNets();
    }
  }

  private async clearAllNetFilters() {
    for (const [key] of Object.entries(this.netFilters)) {
      await this.netFilterClear(this.netFilters[key], false, true);
    }
    this.setNetFiltersDisabled();
    this.netFilters.searchText = null;
    this.netFilters.skip = 0;
    this.netFiltersSaveLocal();
    this.getNets();
  }

  private netFilterGetSelectedOptions(netFilter) {
    return netFilter.options.filter((option) => {
      return netFilter.values[option.Id];
    });
  }

  private async netFilterReset(filter) {
    if (filter.defaultValues) {
      if (typeof filter.defaultValues === 'function') {
        filter.values = await filter.defaultValues();
      } else {
        filter.values = filter.defaultValues;
      }
      this.setNetFilterValueLabel(filter);
    } else {
      this.netFilterClear(filter);
    }
  }

  private setNetFilterInputFocus(netFilter, state: boolean) {
    setTimeout(() => {
      this.netFilterInputInFocus[netFilter.name] = state;
    });
  }

  private netFilterSearchKeydown(netFilter, event) {
    if (event.key == 'Escape' || event.key == 'Esc' || event.keyCode == 27) {
      netFilter._search = null;
    }
    return true;
  }

  private netFilterToggleVisible(netFilter, event) {
    netFilter._visible = event.target.checked;
    this.netFilterClear(netFilter); // Will also trigger netFiltersSaveLocal
  }

  private netFiltersSaveLocal() {
    const data: any = {};

    for (const [key, value] of Object.entries(this.netFilters)) {
      const filter = this.netFilters[key];

      if (!filter || typeof filter === 'undefined') {
        continue;
      } else if (typeof filter === 'string' || typeof filter === 'number') {
        data[key] = filter;
      } else {
        data[key] = {
          values: filter.values,
          visible: filter._visible ?? false,
        };
      }
    }

    localStorage.setItem(this.getKey(), JSON.stringify(data));
  }

  public setupNetFilters() {
    return new Promise(async (resolve, reject) => {
      /* 
        GET OPTIONS FOR FILTERS
      */
      const netTypeOptions: any = async () => {
        const data: any = await this.netTypeService.getAllCached().catch((err) => this.errorService.handleError(err));
        return data.filter((it: any) => it.IsFilterable);
      };

      const netShapeOptions: any = async () => {
        const data: any = await this.netShapeService.getAllCached().catch((err) => this.errorService.handleError(err));
        return data.filter((it: any) => !it.IsDeleted);
      };

      const netStatusOptions: any = async () => {
        const data: any = await this.netStatusService.getAllCached().catch((err) => this.errorService.handleError(err));
        if (!data.find((x) => x.Id === -1)) {
          data.unshift({
            Name: 'Kun aktive',
            NameEn: 'Active only',
            Id: -1,
            SortIndex: -1,
            NameEs: null,
            IsDeleted: false,
          });
        }
        return data.filter((x) => !x.IsDeleted).sort((a, b) => (a.SortIndex > b.SortIndex ? 1 : -1));
      };

      const receivedFromSiteOptions: any = async () => {
        const customerIds = this.netFilters?.customerIds?._selectedOptions;

        if (customerIds && customerIds.length === 1) {
          const data = await this.siteService
            .getAll(`?$filter=CustomerId eq ${customerIds[0].Id}&$orderby=Name`)
            .catch((err) => this.errorService.handleError(err));
          return data;
        } else {
          return [];
        }
      };

      const producerOptions: any = async () => {
        let data: any = await this.producerService.getAllCached().catch((err) => this.errorService.handleError(err));
        data = data.sort((a, b) => (a.Name > b.Name ? 1 : -1));
        return data.filter((it: any) => !it.IsDeleted);
      };

      const dimensionClassOptions: any = async () => {
        let data: any = await this.dimensionClassService
          .getAllCached()
          .catch((err) => this.errorService.handleError(err));
        data = data.sort((a, b) => (a.Name > b.Name ? 1 : -1));
        return data.filter((it: any) => !it.IsDeleted);
      };

      const customerIdsOptions: any = async () => {
        let data: any = await this.customerService.getAll();
        if (this.industryId === Models.Industries.Fishery) {
          data = data.filter((it) => it.IndustryId === Models.Industries.Fishery);
        }
        return data.sort((a, b) => (a.Name > b.Name ? 1 : -1));
      };

      const industryIdOptions: any = async () => {
        const data: any = await this.industryService.getAllCached().catch((err) => this.errorService.handleError(err));
        return data.filter((it: any) => !it.IsDeleted);
      };

      /*
        NETFILTERS
      */
      const netFilters: NetFilter = {
        /* Kunde */
        customerIds: {
          name: 'customerIds',
          label: this.i18n.tr('general.customer'),
          type: this.netFilterTypes.CHECKBOX,
          options: await customerIdsOptions(),
          optionsFunc: customerIdsOptions,
          query: 'customerIds',
          clear: () => {
            void this.netFilterClear(this.netFilters.customerIds, true);
            if (this.context === 'customer-list' || this.context === 'special-product-list-customer-list') {
              netFilters.customerIds.values = { [this.customerId]: true };
            }
          },
        },

        /* Maskestørrelse */
        meshsize: {
          name: 'meshsize',
          label: this.i18n.tr('net.meshsizes'),
          type: this.netFilterTypes.RANGE,
        },

        /* Omkrets merd */
        circumference: {
          name: 'circumference',
          label: this.i18n.tr('netdimension.CircumferenceCage'),
          type: this.netFilterTypes.RANGE,
        },

        /* Dybde til bunntau */
        depth: {
          name: 'depth',
          label: this.i18n.tr('net.DepthBottomRope'),
          type: this.netFilterTypes.RANGE,
        },

        /* fasong */
        netShape: {
          name: 'netShape',
          label: this.i18n.tr('notlogg.netshape'),
          type: this.netFilterTypes.CHECKBOX,
          options: await netShapeOptions(),
          query: 'netShapeIds',
        },

        /* På utleveringsliste */
        hasPlannedDeliveryDate: {
          name: 'hasPlannedDeliveryDate',
          label: this.i18n.tr('servicefilters.hasPlannedDeliveryDate'),
          type: this.netFilterTypes.RADIO_SIMPLE,
          options: this.i18n.tr('servicefilters.hasPlannedDeliveryDateOnly'),
        },

        /* Vis spesialprodukter */
        showSpecialProducts: {
          name: 'showSpecialProducts',
          label: this.i18n.tr('servicefilters.showSpecialProducts'),
          type: this.netFilterTypes.RADIO_SIMPLE,
          options: this.i18n.tr('servicefilters.showSpecialProductsOnly'),

          defaultValues: () => {
            return this.industryId === Models.Industries.Fishery ? true : null;
          },
          prioritizeDefault: true,
        },

        /* Kasserte varer */
        /*         showDiscardedNets: {
          name: 'showDiscardedNets',
          label: this.i18n.tr('servicefilters.discardedNets'),
          type: this.netFilterTypes.RADIO_SIMPLE,
          options: this.i18n.tr('servicefilters.discardedNetsOnly')
        }, */

        /* På lager */
        /*         showNetsInStorage: {
          name: 'showNetsInStorage',
          label: this.i18n.tr('servicefilters.netsInStorage'),
          type: this.netFilterTypes.RADIO_SIMPLE,
          options: this.i18n.tr('servicefilters.netsInStorageOnly')
        }, */

        /* Status */
        status: {
          name: 'status',
          label: this.i18n.tr('general.status'),
          type: this.netFilterTypes.CHECKBOX,
          options: await netStatusOptions(),
          defaultValues: {
            '-1': true,
          },
          query: (values: object) => {
            const valuesArray = Object.entries(values)
              .filter(([key, value]) => value)
              .map(([key, value]) => key);
            if (valuesArray.length > 0) {
              if (values[-1]) {
                return { key: 'netStatusIds', value: valuesArray.join(',') + ',1,2' };
              } else {
                return { key: 'netStatusIds', value: valuesArray.join(',') };
              }
            } else {
              return { key: 'netStatusIds', value: '1,2' };
            }
          },
          clear: (values: object) => {
            for (const [key, value] of Object.entries(values)) {
              if (key === '-1') {
                values[key] = true;
              } else {
                values[key] = false;
              }
            }
          },
        },

        /* Produsent */
        producerId: {
          name: 'producerId',
          label: this.i18n.tr('filters.producer'),
          type: this.netFilterTypes.RADIO,
          options: await producerOptions(),
        },

        /* Produsert */
        produced: {
          name: 'produced',
          label: this.i18n.tr('filters.producedDate'),
          type: this.netFilterTypes.RANGE_DATE,
        },

        /* Gyldig til */
        validTo: {
          name: 'validTo',
          label: this.i18n.tr('filters.validToDate'),
          type: this.netFilterTypes.RANGE_DATE,
        },

        /* Type */
        netType: {
          name: 'netType',
          label: this.i18n.tr('servicefilters.netType'),
          type: this.netFilterTypes.CHECKBOX,
          options: await netTypeOptions(),
          query: 'netTypeIds',
        },

        dimensionClassId: {
          name: 'dimensionClassId',
          label: this.i18n.tr('netdimension.dimensionClass'),
          type: this.netFilterTypes.RADIO,
          options: await dimensionClassOptions(),
        },

        recyclingCompanyId: {
          name: 'recyclingCompanyId',
          label: this.i18n.tr('service.recyclingCompany'),
          type: this.netFilterTypes.RADIO,
          options: await this.recyclingCompanyService.getAllCached(),
        },

        deliveredRecyclingCompany: {
          name: 'deliveredRecyclingCompany',
          label: this.i18n.tr('service.deliveredRecycling'),
          type: this.netFilterTypes.RANGE_DATE,
        },

        industryId: {
          name: 'industryId',
          label: this.i18n.tr('general.industry'),
          type: this.netFilterTypes.RADIO,
          options: await industryIdOptions(),
          defaultValues: () => {
            return this.industryId;
          },
          prioritizeDefault: true,
        },

        showCombiNet: {
          name: 'showCombiNet',
          label: this.i18n.tr('general.combiNet'),
          type: this.netFilterTypes.RADIO_SIMPLE,
          options: this.i18n.tr('general.onlyCombiNet'),
          _visible: false,
          defaultValues: () => {
            return null;
          },
          clear: () => this.netFilterClearSingle('showCombiNet'),
        },
        showCollect: {
          name: 'showCollect',
          label: this.i18n.tr('general.isMorenotCollect'),
          type: this.netFilterTypes.RADIO_SIMPLE,
          options: this.i18n.tr('general.onlyCollectNet'),
          _visible: false,
          defaultValues: () => {
            return null;
          },
          clear: () => this.netFilterClearSingle('showCollect'),
        },
      };

      // Extra filters that is only available some places
      if (this.context === 'special-product-list' || this.context === 'special-product-list-customer-list') {
        const specialProductTypeOptions: any = async () => {
          const data: any = await this.specialProductTypeService
            .getAllCached()
            .catch((err) => this.errorService.handleError(err));
          return data.filter((x) => !x.IsDeleted).sort((a, b) => (a.SortIndex > b.SortIndex ? 1 : -1));
        };

        /* Type spesialprodukt */
        netFilters.specialProductType = {
          name: 'specialProductType',
          label: this.i18n.tr('notlogg.specialproducttype'),
          type: this.netFilterTypes.CHECKBOX,
          options: await specialProductTypeOptions(),
          query: 'specialProductTypeIds',
        };

        /* Omkrets */
        netFilters.specialProductCircumference = {
          name: 'specialProductCircumference',
          label: this.i18n.tr('specialProduct.Circumference'),
          type: this.netFilterTypes.RANGE,
        };

        /* Lengde */
        netFilters.specialProductLength = {
          name: 'specialProductLength',
          label: this.i18n.tr('specialProduct.Length'),
          type: this.netFilterTypes.RANGE,
        };

        /* Lengde favner */
        netFilters.specialProductLengthFavner = {
          name: 'specialProductLengthFavn',
          label: this.i18n.tr('general.lengthFavn'),
          type: this.netFilterTypes.RANGE,
        };

        /* Bredde */
        netFilters.specialProductWidth = {
          name: 'specialProductWidth',
          label: this.i18n.tr('specialProduct.Width'),
          type: this.netFilterTypes.RANGE,
        };

        /* Bredde favner */
        netFilters.specialProductWidthFavner = {
          name: 'specialProductWidthFavn',
          label: this.i18n.tr('general.widthFavn'),
          type: this.netFilterTypes.RANGE,
        };

        /* Omfar */
        netFilters.specialProductOmfar = {
          name: 'specialProductOmfar',
          label: this.i18n.tr('specialProduct.omfar'),
          type: this.netFilterTypes.RANGE,
        };

        // Remove filters from list
        delete netFilters.meshsize;
        delete netFilters.circumference;
        delete netFilters.depth;
        delete netFilters.netType;
      }

      // Function for refreshing options-list
      // Used with filter-disabling
      this.eventAggregator.subscribe('netFilterOptionsRefresh', async (netFilterKey) => {
        this.netFilters[netFilterKey].options = await netFilters[netFilterKey].optionsFunc();
        this.setNetFilterValueLabel(this.netFilters[netFilterKey]);
      });

      this.eventAggregator.subscribe('netFilterReset', async (filterKey) => {
        this.netFilterReset(this.netFilters[filterKey]);
      });

      // Functions and stuff to do when loading filters

      const netFiltersListLocal = JSON.parse(localStorage.getItem(this.getKey()));

      // Default filters visible
      // (default values are set directly in filters, `defaultValues`)
      if (!netFiltersListLocal) {
        // Spesialprodukter
        if (this.context === 'special-product-list' || this.context === 'special-product-list-customer-list') {
          netFilters.customerIds._visible = true;
          netFilters.hasPlannedDeliveryDate._visible = true;
          netFilters.specialProductType._visible = true;
          if (this.industry === 'fishery') {
            netFilters.specialProductLength._visible = true;
            netFilters.specialProductOmfar._visible = true;
          }
        } else if (this.context === 'customer-list') {
          netFilters.meshsize._visible = true;
          netFilters.circumference._visible = true;
          netFilters.depth._visible = true;
          netFilters.hasPlannedDeliveryDate._visible = true;
          netFilters.netType._visible = true;
        }

        // Default
        else {
          netFilters.customerIds._visible = true;
          netFilters.meshsize._visible = true;
          netFilters.circumference._visible = true;
          netFilters.depth._visible = true;
          netFilters.hasPlannedDeliveryDate._visible = true;
          netFilters.netType._visible = true;
          netFilters.netShape._visible = true;
          netFilters.status._visible = true;
          netFilters.producerId._visible = true;
          netFilters.produced._visible = true;
          netFilters.validTo._visible = true;
          netFilters.validTo._visible = true;
          netFilters.showCollect._visible = false;
          netFilters.showCombiNet._visible = false;

          // Only available in context storageView
          netFilters.showSpecialProducts._visible = true;
          netFilters.industryId._visible = true;
        }
      }

      for (const [key, filter] of Object.entries(netFilters)) {
        // Set default values and helpers for filters
        if (!filter.values) {
          if (netFilters[key].type === this.netFilterTypes.CHECKBOX) {
            netFilters[key].values = {};
            netFilters[key]._selectedOptions = [];
          }

          if (netFilters[key].type === this.netFilterTypes.RADIO) {
            netFilters[key].values = null;
          }

          if (netFilters[key].type === this.netFilterTypes.RANGE) {
            netFilters[key].values = { from: null, to: null };
          }

          if (netFilters[key].type === this.netFilterTypes.RANGE_DATE) {
            netFilters[key].values = { from: null, to: null };
            netFilters[key]._childInFocus = { from: false, to: false };
          }

          if (netFilters[key].type === this.netFilterTypes.RADIO_SIMPLE) {
            netFilters[key].values = null;

            // Helper for RADIO_SIMPLE-filter, so we don't have to write
            // options for show and hide all the times
            if (typeof netFilters[key].options === 'string') {
              netFilters[key].options = [
                { label: this.i18n.tr('general.show'), value: null },
                { label: this.i18n.tr('general.hide'), value: false },
                { label: netFilters[key].options, value: true },
              ];
            }
          }
        }

        let hasDefaultValue = false;

        // Set values to default values, if any
        if (netFilters[key].defaultValues) {
          if (typeof netFilters[key].defaultValues === 'function') {
            netFilters[key].values = await netFilters[key].defaultValues();
          } else {
            netFilters[key].values = netFilters[key].defaultValues;
          }

          if (netFilters[key].values !== undefined && netFilters[key].values !== null) {
            hasDefaultValue = true;
          }
        }

        // Handling localstorage - sets values and visible-state
        if (netFiltersListLocal) {
          if (netFiltersListLocal[key]) {
            if (netFilters[key].prioritizeDefault && hasDefaultValue) {
              netFilters[key]._visible =
                netFiltersListLocal[key].visible === undefined ? true : netFiltersListLocal[key].visible;
            } else {
              netFilters[key]._visible =
                netFiltersListLocal[key].visible === undefined ? true : netFiltersListLocal[key].visible;
              netFilters[key].values = netFiltersListLocal[key].values;
            }
          } else {
            netFilters[key]._visible = true;
          }
        }

        // Show/hide clear-button
        if (netFilters[key].type === this.netFilterTypes.CHECKBOX) {
          netFilters[key]._clearable = Object.values(netFilters[key].values).includes(true);

          if (netFilters[key].defaultValues && typeof netFilters[key] === 'object') {
            const isDefault = Object.entries(netFilters[key].values).every(
              ([valueKey, valueValue]) => !valueValue || netFilters[key].defaultValues[valueKey] == valueValue
            );
            if (isDefault) {
              netFilters[key]._clearable = false;
            }
          }
        }

        if (netFilters[key].type === this.netFilterTypes.RANGE) {
          netFilters[key]._clearable = netFilters[key].values['from'] || netFilters[key].values['to'];
        }

        if (netFilters[key].type === this.netFilterTypes.RANGE_DATE) {
          netFilters[key]._clearable = netFilters[key].values['from'] || netFilters[key].values['to'];
        }

        if (netFilters[key].type === this.netFilterTypes.RADIO_SIMPLE) {
          netFilters[key]._clearable = netFilters[key].values !== null;
        }

        if (netFilters[key].type === this.netFilterTypes.RADIO) {
          netFilters[key]._clearable = netFilters[key].values !== null;
        }

        // Set selected options (works for checkboxes)
        this.setNetFilterSelectedOptions(netFilters[key]);

        // Sets value-label
        this.setNetFilterValueLabel(netFilters[key]);
      }

      // Some final overrides for contexts
      if (this.context === 'customer-list' || this.context === 'special-product-list-customer-list') {
        netFilters.customerIds._visible = false;
      }

      // Should only be visible in storageView-context
      if (this.context !== 'storageView') {
        delete netFilters.showSpecialProducts;
        delete netFilters.industryId;
      }

      if (netFiltersListLocal) {
        if (netFiltersListLocal.orderBy) {
          netFilters.orderBy = netFiltersListLocal.orderBy;
        }
        if (netFiltersListLocal.orderByDirection) {
          netFilters.orderByDirection = netFiltersListLocal.orderByDirection;
        }
        if (netFiltersListLocal.skip && this.context !== 'storageView') {
          netFilters.skip = netFiltersListLocal.skip;
        }
        if (netFiltersListLocal.top) {
          netFilters.top = netFiltersListLocal.top;
        }
        if (netFiltersListLocal.searchText) {
          netFilters.searchText = netFiltersListLocal.searchText;
        }
      }

      if (this.selectedFilters) {
        for (const [key] of Object.entries(netFilters) as any) {
          if (!this.selectedFilters.includes(key)) {
            delete netFilters[key];
          }
        }
      }

      this.netFilters = netFilters;

      return resolve(true);
    });
  }

  private netFilterMouseEnter(netFilter) {
    this.setNetFilterInputFocus(netFilter, true);

    if (netFilter.type === this.netFilterTypes.RANGE_DATE && netFilter._dropdownRef) {
      const left = window.scrollX + netFilter._dropdownRef.getBoundingClientRect().left;
      const width = 477; // Width of datepicker-filter
      const isOverflowing = left + width > window.innerWidth ? true : false;
      if (isOverflowing) {
        netFilter._dropdownRef.classList.add('servicefilter-dropdown--datefilter-overflow-fix');
      } else {
        netFilter._dropdownRef.classList.remove('servicefilter-dropdown--datefilter-overflow-fix');
      }
    }
  }
}
